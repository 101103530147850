.pin-container {
  padding: 0 2em 2em 2em;
  width: calc(100% - 4em);
}

button.pin-ok {
  font-size: 1.25rem;
  display: block;
  margin-top: 1.5em;
  background-color: var(--color-primary);
  color: var(--color-text-white);
  height: 38px;
  width: 123px;
  border-radius: 3px;
  border: 0;
}

button.pin-ok:hover {
  background-color: var(--color-text-white);
  color: var(--color-primary);
  cursor: pointer;
  border: 1px solid;
}


button.pin-ok.disabled {
  background-color: #f2f2f2;
  cursor: default;
  color: #888888;
  border-color: transparent;
}

button.pin-ok.disabled:hover {
  cursor: default;
  background-color: #f2f2f2;
  color: #888888;
}

.pin-input {
  font-size: 1.25rem;
  height: 1.5em;
  width: 2.5em;
  padding-left: .75em;
  border: 1px solid var(--color-input-border);
  border-radius: .25em;
}

.pin-input:focus {
  outline: none !important;
  border: 1px solid black;
}

.pin-input.error {
  border: 1px solid red;
}

.pin-input.error:focus {
  outline: red !important;
}