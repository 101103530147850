h1.title {
  font-size: 2.25em;
  color: var(--color-primary);
  font-weight: 400;
}

.main-container {
  border-radius: 6px;
  border: 1px solid #D4D4D4;
  width: 700px;
  margin: 4em 1em 1em 1em;
  min-height: 450px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: auto;
  height: 100%;
}

.logo {
  margin-top: 15px;
  width: 250px;
  height: 30px;
}

@media only screen and (min-width: 380px) {
  .logo {
    margin-top: 15px;
    width: 376px;
    height: 40px;
  }
}

header {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100px;
  width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.navigation{
  max-width: fit-content;
}
.header-container {
  max-width: fit-content;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
}

main {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  background-color: white;
}

