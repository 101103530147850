
html, body {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
}

h1 {
  font-family: Arial, Georgia, 'Times New Roman', Times, serif;
  font-size: 28px;
}

p {
  font-size: 1.25em;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #F5F5F5;
  font-family: -apple-system, BlinkMacSystemFont, 'Arial','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-primary: #005FA5;
  --color-secondary: #EAF4F7;
  --color-tertiary: #043B62;
  --color-text-white: white;
  --color-text-black: black;
  --color-input-border: #CED4DA;
  --color-warning: #DD4F2B;
  --color-warning-background: rgba(221, 79, 43, 0.05);
  --color-warning-border: rgba(221, 79, 43, 0.2);
}