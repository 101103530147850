footer {
  width: 100%;
  background-color: var(--color-tertiary);
}

footer li a {
  margin-left: 1em;
  color: white;
  font-size: 14px;
}

footer span.footer-copyright {
  color: var(--color-secondary);
  padding: 1em;
  font-size: 12px;
}

footer ul.footer-links {
  padding: 0;
  margin: 0;
  list-style: none;
}

@media only screen and (max-width: 819px){
  .footer-container {
    display: flex;
    flex-direction: column;
  }
  
  footer li {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3em;
    border-style: none none solid none;
    border-width: 1px;
    border-color: var(--color-secondary);
  }
}



@media only screen and (min-width: 820px) {
  .footer-container {
    max-width: 960px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    height: max-content;
  }
  
  footer li {
    float: left;
    padding: 1em;
  }

  footer li a {
    margin: 0;
  }

}
