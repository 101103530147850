.error-container {
  background-color: var(--color-warning-background);
  border-radius: 4px;
  border: 1px solid var(--color-warning-border);
  margin-top: 1.75em;
  width: 100%;
}

.error-messages {
  display: flex;
  margin-left: 1.5em;
  height: inherit;
}

p.error {
  color: var(--color-warning);
}

p.bold {
  font-weight: bold;
}